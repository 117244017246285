import "pinia"
import { PiniaPluginContext } from "pinia"
import { getQueryParams, updateQuery } from "@evercam/shared/utils"
import { camelizeKeys } from "humps"

declare module "pinia" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface DefineStoreOptionsBase<S, Store> {
    syncWithUrl?: Array<keyof S>
  }
}

const maybeCastBoolean = (value: any): boolean | any => {
  if (typeof value !== "string") {
    return value
  }

  switch (value) {
    case "true":
      return true
    case "false":
      return false
    default:
      return value
  }
}

const maybeCastNumber = (value: any): number | any => {
  if (typeof value === "number" || typeof value !== "string") {
    return value
  }

  if (/^-?\d+$/.test(value)) {
    return parseInt(value, 10)
  }

  if (/^-?\d+\.\d+$/.test(value)) {
    return parseFloat(value)
  }

  return value
}

const maybeCastValue = (
  initialValue: any,
  value: any
): boolean | number | any => {
  if (Array.isArray(initialValue) && !Array.isArray(value)) {
    return [value]
  }

  if (Array.isArray(value)) {
    return value
  }

  return maybeCastBoolean(maybeCastNumber(value))
}

export function PiniaPluginSyncUrlParams(context: PiniaPluginContext): void {
  const { store, options } = context
  let isInitialized = false
  let previousStore = {} as any

  const syncWithUrl = options.syncWithUrl as string[]
  if (syncWithUrl && syncWithUrl.length > 0) {
    const initStoreFromUrl = () => {
      const query = camelizeKeys(getQueryParams())
      syncWithUrl.forEach((prop: string) => {
        if (query && query[prop] !== undefined) {
          const stateValue = query[prop]
          const initialStateValue = previousStore[prop]
          store[prop] = maybeCastValue(initialStateValue, stateValue)
        }
      })
    }

    const updateUrlFromStore = () => {
      const queryParams: Record<string, any> = camelizeKeys(getQueryParams())
      syncWithUrl.forEach((prop: string) => {
        const shouldUpdateUrl =
          store[prop] !== previousStore[prop] || prop in queryParams

        if (shouldUpdateUrl && store[prop] !== undefined) {
          queryParams[prop] = store[prop]
        }
      })
      updateQuery(queryParams, false)
      previousStore = { ...store.$state }
    }

    initStoreFromUrl()

    store.$subscribe(
      () => {
        if (!isInitialized) {
          isInitialized = true
          initStoreFromUrl()

          return
        }
        updateUrlFromStore()
      },
      { detached: true }
    )
  }
}
